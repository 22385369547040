import React, { useRef, useEffect } from "react";

function BreakoutGame() {
  const canvasRef = useRef(null);

  let score = 0;
  let lives = 3;


  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    let ballRadius = 10;
    let x = canvas.width / 2;
    let y = canvas.height - 30;
    let dx = 4;
    let dy = -4;
    const paddleHeight = 10;
    const paddleWidth = 75;
    let paddleX = (canvas.width - paddleWidth) / 2;

    const brickWidth = 75;
    const brickHeight = 20;
    const brickPadding = 10;
    const brickOffsetTop = 30;
    const brickOffsetLeft = 30;

    const brickRowCount = Math.floor((canvas.width - brickOffsetLeft) / (brickWidth + brickPadding));
    const brickColumnCount = 4;

    const brickColors = [
      'rgb(74,234,220)',
      'rgb(151,120,209)',
      'rgb(207,42,186)',
      'rgb(238,44,130)',
      'rgb(251,105,98)',
      'rgb(254,248,76)'
    ];

    let bricks = [];
    for (let c = 0; c < brickColumnCount; c++) {
      bricks[c] = [];
      for (let r = 0; r < brickRowCount; r++) {
        bricks[c][r] = { x: 0, y: 0, status: 1, color: brickColors[Math.floor(Math.random() * brickColors.length)] };
      }
    }

    const drawBricks = () => {
      for (let c = 0; c < brickColumnCount; c++) {
        for (let r = 0; r < brickRowCount; r++) {
          if (bricks[c][r].status === 1) {
            const brickX = r * (brickWidth + brickPadding) + brickOffsetLeft;
            const brickY = c * (brickHeight + brickPadding) + brickOffsetTop;
            bricks[c][r].x = brickX;
            bricks[c][r].y = brickY;
            ctx.beginPath();
            ctx.rect(brickX, brickY, brickWidth, brickHeight);
            ctx.fillStyle = bricks[c][r].color;
            ctx.fill();
            ctx.closePath();
          }
        }
      }
    };

    const drawBall = () => {
      ctx.beginPath();
      ctx.arc(x, y, ballRadius, 0, Math.PI * 2);
      ctx.fillStyle = "#0095DD";
      ctx.fill();
      ctx.closePath();
    };

    const drawPaddle = () => {
      ctx.beginPath();
      ctx.rect(paddleX, canvas.height - paddleHeight, paddleWidth, paddleHeight);

      const grd = ctx.createLinearGradient(paddleX, canvas.height - paddleHeight, paddleX + paddleWidth, canvas.height);
      grd.addColorStop(0, 'rgba(6,147,227,1)');
      grd.addColorStop(1, 'rgb(155,81,224)');

      ctx.fillStyle = grd;
      ctx.fill();
      ctx.closePath();
    };

    const collisionDetection = () => {
      for (let c = 0; c < brickColumnCount; c++) {
        for (let r = 0; r < brickRowCount; r++) {
          const b = bricks[c][r];
          if (b.status === 1 && x > b.x && x < b.x + brickWidth && y > b.y && y < b.y + brickHeight) {
            dy = -dy;
            b.status = 0;
            score++; // Increment score when a brick is hit
            setTimeout(() => {
              b.status = 1;
              b.color = brickColors[Math.floor(Math.random() * brickColors.length)];
            }, 5000); 
          }
        }
      }
    };

    const drawScore = () => {
      ctx.font = "16px Arial";
      ctx.fillStyle = "#0095DD";
      ctx.fillText("Score: " + score, 8, 20); // Draw score in the top-left corner
    };
    
    
    
    let lastTime;
    
    const draw = (timestamp) => {
      if (!lastTime) {
        lastTime = timestamp;
      }

      const deltaTime = timestamp - lastTime; // Time elapsed between frames
      lastTime = timestamp;

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      drawBricks();
      drawBall();
      drawPaddle();
      drawScore(); // Add this line

      collisionDetection();

      if (x + dx > canvas.width - ballRadius || x + dx < ballRadius) {
        dx = -dx;
      }
      if (y + dy < ballRadius) {
        dy = -dy;
      } else if (y + dy > canvas.height - ballRadius) {
        if (x > paddleX && x < paddleX + paddleWidth) {
          dy = -dy;
        } else {
          x = paddleX + paddleWidth / 2;
          y = canvas.height - 40; 
          dy = -Math.abs(dy);
          
          // Reset score
          score = 0;
        }
      }

      x += dx * (deltaTime / 16.67); // Assuming 60 frames per second, so 1000ms/60 = 16.67ms per frame
      y += dy * (deltaTime / 16.67);

      requestAnimationFrame(draw);
    };

    const updatePaddlePosition = (newX) => {
      if (newX > 0 && newX < canvas.width) {
        paddleX = newX - paddleWidth / 2;
      }
    };
    
    document.addEventListener("mousemove", (e) => {
      const relativeX = e.clientX - canvas.offsetLeft;
      updatePaddlePosition(relativeX);
    });

    document.addEventListener("touchmove", (e) => {
      const touch = e.touches[0];
      const touchX = touch.clientX - canvas.offsetLeft;
      updatePaddlePosition(touchX);
      e.preventDefault(); // Prevents the default scrolling when touching and moving
    });

    requestAnimationFrame(draw);
  }, []);

  return (
    <canvas 
      ref={canvasRef} 
      width={window.innerWidth} 
      height={window.innerHeight} 
      style={{ 
        position: "absolute", 
        top: 0, 
        left: 0, 
        zIndex: 3,
        pointerEvents: "none" 
      }} 
    />
  );
}

export default BreakoutGame;
