import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardMedia, IconButton, Typography, Box, CircularProgress } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { Storage } from 'aws-amplify';
import { parseBlob } from 'music-metadata-browser';

function getPictureDataURL(picture) {
  if (!picture || !picture.data || !picture.format) return null;
  const { data, format } = picture;
  const base64String = Buffer.from(data).toString('base64');
  return `data:${format};base64,${base64String}`;
}

function MusicCard() {
  const theme = useTheme();

  const [isPlaying, setIsPlaying] = useState(false);
  const [songURL, setSongURL] = useState(null);
  const [songMetadata, setSongMetadata] = useState(null);
  const [loading, setLoading] = useState(true);
  const audioRef = useRef(null);

  useEffect(() => {
    async function fetchAndParseSong() {
      try {
        const signedURL = await Storage.get('OpenTheGate.mp3', {
          level: 'public',
          expires: 3600,
          validateObjectExistence: true
        });
        setSongURL(signedURL);

        // Fetch the song and parse its metadata
        const response = await fetch(signedURL);
        const blob = await response.blob();
        const metadata = await parseBlob(blob);
        setSongMetadata(metadata);
      } catch (error) {
        console.error("Error fetching or parsing song:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchAndParseSong();
  }, []);

  useEffect(() => {
    if (isPlaying && audioRef.current) {
      audioRef.current.play();
    } else if (!isPlaying && audioRef.current) {
      audioRef.current.pause();
    }
  }, [isPlaying]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Card sx={{ display: 'flex', position: 'relative', overflow: 'hidden' }}>
      <CardMedia
        component="img"
        sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: -1, backgroundSize: 'cover' }}
        image={songMetadata && songMetadata.common.picture && songMetadata.common.picture[0]
          ? getPictureDataURL(songMetadata.common.picture[0])
          : '/static/images/cards/live-from-space.jpg'}
        alt="Music Artwork"
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', padding: 2, backgroundColor: 'rgba(0, 0, 0, 0.5)', justifyContent: 'space-between' }}>
        <CardContent>
          <Typography component="div" variant="h5" color="text.primary">
            {songMetadata && songMetadata.common.title || 'Song Title'}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
            {songMetadata && songMetadata.common.artist || 'Artist Name'}
          </Typography>
        </CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', pb: 1 }}>
          <IconButton onClick={() => setIsPlaying(!isPlaying)}>
            {isPlaying 
              ? <PauseIcon sx={{ color: theme.palette.primary.main, height: 38, width: 38 }} />
              : <PlayArrowIcon sx={{ color: theme.palette.primary.main, height: 38, width: 38 }} />}
          </IconButton>
        </Box>
      </Box>
      {songURL && (
        <audio
          ref={audioRef}
          src={songURL}
          onEnded={() => setIsPlaying(false)}
        />
      )}
    </Card>
  );
}

export default MusicCard;




