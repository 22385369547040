import React from 'react';
import { Typography, Container, Box } from '@mui/material';

function Resume() {
  return (
    <Container sx={{ maxHeight: "100vh", overflowY: "auto" }}>
      <Typography variant="h2" gutterBottom sx={{ fontFamily: "Pacifico, cursive" }}>Josh Lee</Typography>
      <Typography gutterBottom>Earth, x@joshdlee.com</Typography>

      <Box my={3}>
        <Typography variant="h4" gutterBottom sx={{ fontFamily: "Pacifico, cursive" }}>Summary:</Typography>
        <Typography paragraph>
          Dedicated Cloud Leader with a knack for leading teams, optimizing cloud solutions, and driving results. 
          With a strategic vision complemented by hands-on experience, I excel in guiding teams to achieve excellence 
          and foster innovation. Eager to leverage my leadership capabilities in a managerial role to drive team 
          success and business growth.
        </Typography>
      </Box>

      <Box my={3}>
        <Typography variant="h4" gutterBottom sx={{ fontFamily: "Pacifico, cursive" }}>Certifications:</Typography>
        {[
          "AWS Certified Solutions Architect - Professional",
          "AWS Certified DevOps Engineer - Professional",
          "AWS Certified Security - Specialty",
          "AWS Certified Advanced Networking - Specialty",
          "AWS Certified Big Data - Specialty",
          "AWS Certified Developer - Associate",
          "AWS Certified Solutions Architect - Associate",
          "AWS Certified SysOps Administrator - Associate",
          "Azure AZ-900 Fundamentals",
          "FinOps Foundation FinOps Certified Practitioner",
          "HashiCorp Certified Terraform - Associate",
          "Cisco Certified Network Associate - Routing & Switching"
        ].map(certification => (
          <Typography key={certification} paragraph>
            • {certification}
          </Typography>
        ))}
      </Box>

      <Box my={3}>
        <Typography variant="h4" gutterBottom sx={{ fontFamily: "Pacifico, cursive" }}>Work Experience:</Typography>

        <Typography variant="h6" gutterBottom sx={{ fontFamily: "Pacifico, cursive" }}>NetApp Sr Solutions Architect (09/2020 - Present)</Typography>
        {[
          "Demonstrated leadership by spearheading sales efforts for Spot.io flagship products, consistently achieving targets.",
          "Mentored and helped develop training modules for new hires, ensuring a smooth and efficient onboarding process.",
          "Fostered strong relationships with partners, co-creating effective sales strategies and driving product evangelism.",
          "Addressed client needs by conducting tailored workshops, enhancing product understanding and satisfaction.",
          "Honored with the President's Award twice for outstanding contributions and performance."
        ].map(experience => (
          <Typography key={experience} paragraph>
            • {experience}
          </Typography>
        ))}

        <Typography variant="h6" gutterBottom sx={{ fontFamily: "Pacifico, cursive" }}>Cloudticity Solutions Architect (11/2018 - 09/2020)</Typography>
        {[
          "Led pivotal AWS cloud architecture projects, ensuring timely delivery and adherence to client requirements.",
          "Assisted colleagues in navigating complex cloud challenges, fostering a culture of knowledge-sharing and continuous learning.",
          "Collaborated cross-functionally to integrate compliance metrics into client dashboards, enhancing transparency and client trust."
        ].map(experience => (
          <Typography key={experience} paragraph>
            • {experience}
          </Typography>
        ))}

        <Typography variant="h6" gutterBottom sx={{ fontFamily: "Pacifico, cursive" }}>UW Medicine Sr Datacenter Analyst (2/2013 - 11/2018)</Typography>
        {[
          "Successfully led the organization's transition from traditional network infrastructure to cloud-based solutions.",
          "Facilitated collaborative efforts with IT teams to ensure seamless system migrations and operational efficiency."
        ].map(experience => (
          <Typography key={experience} paragraph>
            • {experience}
          </Typography>
        ))}

        <Typography variant="h6" gutterBottom sx={{ fontFamily: "Pacifico, cursive" }}>Dell Technologies Sr Datacenter Associate (1/2012 - 2/2013)</Typography>
        {[
          "Implemented best practices to address datacenter inefficiencies, ensuring optimal operations.",
          "Engaged with cross-functional teams, fostering communication and alignment on datacenter projects and initiatives."
        ].map(experience => (
          <Typography key={experience} paragraph>
            • {experience}
          </Typography>
        ))}
      </Box>

      <Box my={3}>
        <Typography variant="h4" gutterBottom sx={{ fontFamily: "Pacifico, cursive" }}>Education:</Typography>

        <Typography variant="h6" gutterBottom sx={{ fontFamily: "Pacifico, cursive" }}>University of Washington:</Typography>
        <Typography paragraph>AWS Cloud Application Development Certificate</Typography>

        <Typography variant="h6" gutterBottom sx={{ fontFamily: "Pacifico, cursive" }}>Bellevue College:</Typography>
        {[
          "Bachelor of Science, Computer Science",
          "Associate of Applied Science, Cloud Architecture and Services",
          "Associate of Applied Science, Network Administration"
        ].map(education => (
          <Typography key={education} paragraph>
            • {education}
          </Typography>
        ))}
      </Box>
    </Container>
  );
}

export default Resume;
