import React, { useState, useEffect } from "react";
import {
  Typography,
  Container,
  Box,
  ThemeProvider,
  createTheme,
  IconButton,
  CssBaseline,
  GlobalStyles
} from "@mui/material";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import MusicCard from "./MusicCard";
import { Buffer } from "buffer";
import process from "process";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Resume from "./Resume";
import Atropos from "atropos/react";
import "atropos/css";
import { useSpring, animated, config } from "@react-spring/web";
import BreakoutGame from "./BreakoutGame";

window.process = process;
window.Buffer = Buffer;

function TypingText() {
  const fullText = "JoshDLee";
  const [index, setIndex] = useState(0);

  // Randomize animation configuration
  const randomConfig = {
    tension: Math.floor(Math.random() * 1000),
    friction: Math.floor(Math.random() * 100) + 1,
  };

  const props = useSpring({
    from: { opacity: 0, transform: "translateY(100%)" },
    to: { opacity: 1, transform: "translateY(0)" },
    config: randomConfig,
    reset: true,
    reverse: index % 2 === 0,
    shadow: true,
    onRest: () => setIndex(index + 1),
  });

  useEffect(() => {
    if (index < fullText.length) {
      // Randomize delay for a different typing speed each time
      const randomDelay = Math.floor(Math.random() * 500);
      setTimeout(() => {
        setIndex(index + 1);
      }, randomDelay);
    }
  }, [index]);

  const displayText = fullText.substring(0, index);

  return (
    <Typography
      variant="h1"
      component="div"
      sx={{
        color: "text.primary",
        fontFamily: "Pacifico, cursive",
        fontSize: "10vw",
        textAlign: "center",
        width: "100%",
      }}
    >
      {displayText.split("").map((char, i) => (
        <animated.span key={i} style={i === index - 1 ? props : {}}>
          {char}
        </animated.span>
      ))}
    </Typography>
  );
}

function App() {
  const [themeMode, setThemeMode] = useState("dark");

  const toggleTheme = () => {
    setThemeMode((prevMode) => (prevMode === "dark" ? "light" : "dark"));
  };

  const theme = createTheme({
    palette: {
      mode: themeMode,
      primary: {
        main: "#90caf9",
      },
      secondary: {
        main: "#f48fb1",
      },
    },
    typography: {
      fontFamily: "Roboto, Arial, sans-serif",
    },
  });

  return (
    <Router>
        <BreakoutGame />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles
          styles={{
            body: {
              backgroundColor: theme.palette.background.default,
              overflow: "hidden",
            },
          }}
        />
        <Routes>
          <Route path="/resume" element={<Resume />} />
          <Route
            path="/"
            element={
              // <Atropos
              //   activeOffset={40}
              //   shadowScale={1.05}
              //   onEnter={() => console.log("Enter")}
              //   onLeave={() => console.log("Leave")}
              //   onRotate={(x, y) => console.log("Rotate", x, y)}
              //   shadow={true}
              // >
                <Container
                  maxWidth={false}
                  sx={{
                    height: "100vh",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: theme.palette.background.default,
                    position: "relative",
                  }}
                >
                  <IconButton
                    onClick={toggleTheme}
                    sx={{
                      position: "absolute",
                      top: "2vh",
                      right: "2vw",
                      color: theme.palette.text.primary,
                      width: "10vw",
                      height: "10vw",
                      maxWidth: 80,
                      maxHeight: 80,
                      touchAction: "none",
                      cursor: "pointer",
                    }}
                  >
                    <LightbulbIcon sx={{ fontSize: "8vw", maxWidth: 72 }} />
                  </IconButton>
                  <TypingText />
                  <MusicCard />
                </Container>
              // </Atropos>
            }
          />
        </Routes>
      </ThemeProvider>
    </Router>
  );
}

export default App;